<template>
	<div>
		<template v-if="type == 'circle'">
			<v-tooltip top content-class="custom-top-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<v-chip
						v-bind="attrs"
						v-on="on"
						:color="statusObject ? statusObject.color : ''"
						:text-color="statusObject ? statusObject.textcolor : ''"
						style="width: 19px !important; height: 23px !important"
					></v-chip>
				</template>
				<span> {{ statusObject ? statusObject.text : "" }} </span>
			</v-tooltip>
		</template>
		<template v-else>
			<v-chip
				:class="customClass"
				:color="statusObject ? `${statusObject.color}3e` : ''"
				:text-color="statusObject ? statusObject.color : ''"
				:style="`border:1px solid ${statusObject ? statusObject.color : ''}`"
				label
			>
				{{ statusObject ? statusObject.text : "" }}
			</v-chip>
		</template>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { find } from "lodash";

export default {
	name: "btx-chip",
	props: {
		status: {
			type: String,
			default: null,
		},
		customClass: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters(["listingStatus"]),
		statusObject() {
			return find(this.listingStatus, { value: this.status });
		},
	},
};
</script>
