<template>
	<v-sheet class="group" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="2" class="py-0 my-auto custom-headeing">
				All Sales/Rental
				<!-- <v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="true"
					:items="listingArr"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.document_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select> -->
			</v-col>
			<v-col md="10" class="py-1 my-auto text-right listing-right">
				<v-layout class="justify-end">
					<div class="mx-2" style="max-width: 300px">
						<date-range-picker
							v-on:click:clear="
								s_search['date-range'] = [];
								searchOrders();
							"
							hide-details
							hide-top-margin
							clearable
							v-model="s_search['date-range']"
						></date-range-picker>
					</div>
					<div class="mx-2" style="max-width: 300px">
						<select-input
							v-on:click:clear="
								s_search['incharge-officer'] = [];
								searchOrders();
							"
							clearable
							:items="incharge_items"
							hide-details
							custom-class="pt-0"
							placeholder="Staff"
							multiple
							v-model="s_search['incharge-officer']"
						></select-input>
					</div>
					<div class="mx-2" style="max-width: 300px">
						<select-input
							v-on:click:clear="
								s_search['r-type'] = [];
								searchOrders();
							"
							clearable
							:items="item_type_items"
							hide-details
							custom-class="pt-0"
							placeholder="Type"
							multiple
							v-model="s_search['r-type']"
						></select-input>
					</div>
					<div class="mx-2" style="max-width: 300px">
						<select-input
							v-on:click:clear="
								s_search['t-type'] = [];
								searchOrders();
							"
							clearable
							:items="transaction_type_items"
							hide-details
							custom-class="pt-0"
							placeholder="Transaction Type"
							multiple
							v-model="s_search['t-type']"
						></select-input>
					</div>
					<div class="d-flex">
						<v-btn
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							:disabled="pageLoading"
							v-on:click="searchOrders()"
							><v-icon center> mdi-magnify </v-icon></v-btn
						>
						<!-- <v-btn
							v-if="getPermission('checkout:create')"
							depressed
							:disabled="pageLoading"
							tile
							color="blue darken-4"
							class="text-white"
							v-on:click="selectRequeseteddialog = true"
						>
							<v-icon left> mdi-plus </v-icon>
							Create 
						</v-btn> -->
						<v-btn
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							tile
							v-on:click="exportDialog = true"
						>
							<v-icon>mdi-file-excel</v-icon>
						</v-btn>
						<v-menu
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-edit</v-icon>
								</v-btn>
							</template>
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('group')"
							>
								<template v-for="cols in draggableThead">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('group')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</v-menu>
					</div>
				</v-layout>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Related #, Name, Staff"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="issue"
			delete-endpoint="rentals/"
			:dialog-update="true"
			v-on:reload:content="filterRows"
			v-on:createCheckInOut="createCheckin"
			v-on:update:dialog="updateDialog"
			delete-note="All rentals of this group will also be deleted."
		></Table>
		<template v-if="checkoutDialog">
			<CheckoutTemplate
				:type="relatedType"
				:endpoint="relatedEndPoint"
				:type-uuid="relatedUuid"
				:uuid="uuid"
				v-on:success="
					checkoutDialog = false;
					uuid = null;
					relatedUuid = null;
					relatedType = null;
					relatedEndPoint = null;
					getListing();
				"
				v-on:close="checkoutDialog = false"
				:checkout-dialog="checkoutDialog"
			></CheckoutTemplate>
		</template>
		<template v-if="selectRequeseteddialog">
			<SelectRequested
				v-on:selectRelatedTo="selectRelatedTo"
				v-on:close="selectRequeseteddialog = false"
				:checkout-dialog="selectRequeseteddialog"
			></SelectRequested>
		</template>
		<template v-if="checkinDialog">
			<CheckinTemplate
				:type="relatedTypeCheckIn"
				:endpoint="relatedEndPointCheckIn"
				:type-uuid="relatedUuidCheckIn"
				:checkout-uuid="checkOutUuid"
				v-on:success="
					checkinDialog = false;
					checkOutUuid = null;
					relatedUuidCheckIn = null;
					relatedTypeCheckIn = null;
					relatedEndPointCheckIn = null;
					getListing();
				"
				v-on:close="checkinDialog = false"
				:checkin-dialog="checkinDialog"
			></CheckinTemplate>
		</template>
		<template v-if="exportCurrentDialog">
			<ExportDialog
				endpoint="asset/export?type=asset&current=1"
				title="Assets"
				current
				:export-dialog.sync="exportCurrentDialog"
				v-on:close="exportCurrentDialog = false"
			></ExportDialog>
		</template>
		<template v-if="exportDialog">
			<ExportDialog
				endpoint="issue/export?status=checked-out"
				title="Issue"
				:export-dialog.sync="exportDialog"
				v-on:close="exportDialog = false"
			></ExportDialog>
		</template>
		<template v-if="importDialog">
			<ImportDialog
				endpoint="issue/export?status=checked-out"
				title="Issue"
				:import-dialog.sync="importDialog"
				v-on:close="importDialog = false"
			></ImportDialog>
		</template>
		<template v-if="advanceSearch">
			<FilterDialog
				title="Assets"
				:filter-dialog.sync="advanceSearch"
				:btx-filter.sync="listingFilter"
				:btx-query.sync="btxQuery"
				v-on:do-search="doAdvanceSearch"
				v-on:close="advanceSearch = false"
			>
			</FilterDialog>
		</template>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import CheckoutTemplate from "@/view/components/CheckoutTemplate";
import SelectRequested from "@/view/components/Select-Checkout-Requested";
import CheckinTemplate from "@/view/components/CheckinTemplate";
import SelectInput from "@/view/components/SelectInput";
import DateRangePicker from "@/view/components/DateRangePicker";

import ObjectPath from "object-path";
import { mapGetters } from "vuex";
import { isString, toSafeInteger, map } from "lodash";

export default {
	name: "group-listing",
	title: "Listing Group",
	mixins: [ListingMixin],
	data() {
		return {
			uuid: null,
			s_search: {
				"date-range": [],
				"incharge-officer": [],
				"r-type": [],
				"t-type": [],
			},
			incharge_items: [],
			item_type_items: [
				{ text: "Asset", value: "asset" },
				{ text: "Asset Stock", value: "asset-stock" },
			],
			transaction_type_items: [
				{ text: "Issued", value: "issued" },
				{ text: "Received", value: "received" },
				{ text: "Rental", value: "is_rent" },
			],
			listingStatusArr: [
				{
					id: 10,
					type: "issue",
					text: "All Issued",
					value: "checked-out",
					description: "List of All Issued",
					color: "blue",
					textcolor: "white",
					dbvalue: 99,
					status_count: 0,
					order: 0,
				},
			],
			dialog: false,
			checkinDialog: false,
			relatedUuid: null,

			relatedType: null,
			relatedEndPoint: null,
			checkOutUuid: null,
			relatedUuidCheckIn: null,
			relatedTypeCheckIn: null,
			relatedEndPointCheckIn: null,
			checkoutDialog: false,
			selectRequeseteddialog: false,
			pageTitle: "Issue",
			pageBreadcrumbs: [{ text: "Issue", disabled: true }],
			endpoint: "rentals",
			defaultFilter: {},
			listingArr: [
				{
					id: 10,
					type: "rental",
					text: "All Rental",
					value: "retire",
					description: "List of All Rental",
					color: "blue",
					textcolor: "white",
					dbvalue: 99,
					status_count: 0,
					order: 0,
				},
			],
		};
	},

	components: {
		CheckoutTemplate,
		SelectRequested,
		CheckinTemplate,
		SelectInput,
		DateRangePicker,
	},
	watch: {
		relationTypeId(param) {
			console.log(param);
		},
		/* typeUuid: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param && !this.uuid) {
					this.getRelatedDetails(this.type, param);
				}
			},
		}, */
	},
	methods: {
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.s_search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
		updateDialog(uuid) {
			this.uuid = uuid;
			this.checkoutDialog = true;
		},
		selectRelatedTo(data) {
			this.relatedUuid = data.id;
			this.relatedType = data.type;
			this.relatedEndPoint = data.endpoint;
			if (this.relatedUuid && this.relatedType && this.relatedEndPoint) {
				this.selectRequeseteddialog = false;
				this.checkoutDialog = true;
			}
		},
		createCheckin(data) {
			this.relatedUuidCheckIn = data.asset > 0 ? data.asset_uuid : data.asset_stock_uuid;
			this.relatedTypeCheckIn = data.asset > 0 ? "Asset" : "Asset Stock";
			this.relatedEndPointCheckIn = data.asset > 0 ? "asset" : "asset-stock";
			this.checkOutUuid = data.checkout_uuid;
			this.checkinDialog = true;
		},
	},

	mounted() {
		this.incharge_items = this.localDB("members", []);

		const date_range = ObjectPath.get(this.$route, "query.date-range", []);
		if (isString(date_range)) {
			this.s_search["date-range"] = [date_range];
		} else {
			this.s_search["date-range"] = date_range;
		}

		const incharge_officer = ObjectPath.get(this.$route, "query.incharge-officer", []);
		if (isString(incharge_officer)) {
			this.s_search["incharge-officer"] = [toSafeInteger(incharge_officer)];
		} else {
			this.s_search["incharge-officer"] = map(incharge_officer, (row) => {
				return toSafeInteger(row);
			});
		}

		const r_type = ObjectPath.get(this.$route, "query.r-type", []);
		if (isString(r_type)) {
			this.s_search["r-type"] = [r_type];
		} else {
			this.s_search["r-type"] = r_type;
		}
		const t_type = ObjectPath.get(this.$route, "query.t-type", []);
		if (isString(t_type)) {
			this.s_search["t-type"] = [t_type];
		} else {
			this.s_search["t-type"] = t_type;
		}
	},
	computed: {
		...mapGetters(["localDB", "listingStatus", "listingFilter", "listingSearch"]),
	},
};
</script>
