<template>
	<v-row>
		<v-col>
			<label
				class="custom-input custom-radio rounded cursor-pointer py-0"
				:class="Number(checkboxVal) == 1 ? 'checkbox-border-selected' : 'checkbox-border'"
			>
				<div class="ma-1">
					<v-checkbox
						dense
						hide-details
						:true-value="1"
						:false-value="0"
						color="#ef8b33"
						class="mb-0 p-0"
						v-model="checkboxVal"
					></v-checkbox>
				</div>
				<div class="flex-grow-1 pb-2 text-primary">
					<div class="d-flex align-center" style="height: 34px">
						<h6 class="cr-title">{{ label }}</h6>
					</div>
					<p class="text-sm mb-0" :class="Number(checkboxVal) == 1 ? 'cr-desc-selected' : 'cr-desc'">
						{{ description }}
					</p>
				</div>
			</label>
		</v-col>
	</v-row>
</template>
<script>
export default {
	name: "checkbox-input",
	title: "Checkbox Input",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Boolean, Number, String],
			default: 0,
		},
		id: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		customClass: {
			type: String,
			default: "mt-3 pt-0",
		},
		label: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		row: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(param) {
				this.checkboxVal = param;
			},
		},
		checkboxVal(param) {
			this.$emit("change", param);
		},
	},
	data() {
		return {
			checkboxVal: null,
		};
	},
	/* mounted() {
		this.checkbox = this.value;
	}, */
};
</script>
<style lang="scss" scoped>
.custom-radio {
	display: flex;
	align-items: flex-start;
	gap: 0.375rem;
	&.active {
		background: rgba(var(--v-theme-primary), 0.1);
	}
	.v-radio {
		margin-block-start: -0.25rem;
	}

	.cr-title {
		font-weight: 500;
		color: #5e5c5c;
	}
	.cr-desc {
		color: #a39e9e;
	}
	.cr-desc-selected {
		color: #ef8b33;
	}
}
.checkbox-border {
	border: 1px solid #ccc !important;
	background-color: #b2b8bf3b !important;
}
.checkbox-border-selected {
	border: 1px solid #ef8b33 !important;
	background-color: #efd0b561 !important;
}
</style>
