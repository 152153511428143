<template>
	<v-layout class="phone-text-field">
		<v-flex class="max-width-80px" v-if="true">
			<SelectInput
				:id="stringUnique()"
				hide-details
				:loading="loading"
				:disabled="disabled"
				item-text="text"
				item-value="value"
				:items.sync="countries"
				v-model="countryCode"
				:class="selectClass"
				class="select-country"
				v-on:onchange="$emit('onchange', true)"
				v-on:keyup="$emit('keyup', true)"
			></SelectInput>
		</v-flex>
		<v-flex>
			<v-text-field
				:id="id"
				ref="phoneInput"
				:label="label"
				:loading="loading"
				:disabled="disabled"
				:rules="rules"
				:hide-details="hideDetails"
				outlined
				:prefix="getPrefix"
				v-mask="getMask"
				class="mt-3 pt-0 text-phone"
				v-model="textinput"
				:placeholder="placeholder"
				v-on:onchange="$emit('onchange', true)"
				v-on:keyup="$emit('keyup', true)"
			>
			</v-text-field>
		</v-flex>
	</v-layout>
</template>
<script>
import SelectInput from "@/view/components/SelectInput.vue";
import { trimStart } from "lodash";
export default {
	name: "text-phone-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		selectClass: {
			type: String,
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		value: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			countries: [
				{
					text: "SG",
					value: "+65",
				},
				{
					text: "IND",
					value: "+91",
				},
			],
			countryCode: "+91",
			textinput: null,
		};
	},
	components: {
		SelectInput,
	},
	watch: {
		value() {
			if (this.value) {
				const array = this.value.split("-");
				this.countryCode = array[0] ? array[0] : null;
				this.textinput = array[1] ? array[1] : null;
			} else {
				this.countryCode = "+65";
				this.textinput = null;
			}
		},
		countryCode() {
			const value = trimStart(this.textinput, "-");
			this.$emit("input", `${this.countryCode}-${value}`);
		},
		textinput() {
			const value = trimStart(this.textinput, "-");
			this.$emit("input", `${this.countryCode}-${value}`);
		},
	},
	computed: {
		getPrefix() {
			return `${this.countryCode}-`;
		},
		getMask() {
			if (this.countryCode == "+65") {
				return "########";
			}
			return "##########";
		},
	},
	mounted() {
		if (this.value) {
			const array = this.value.split("-");
			this.countryCode = array[0] ? array[0] : null;
			this.textinput = array[1] ? array[1] : null;
		}
	},
};
</script>
