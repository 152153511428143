import ApiService from "@/core/services/api.service";

export const GetAssetList = (type) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`asset-list`, { type })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetAssetStocksList = (type) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`asset-stock-list`, { type })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetCheckoutList = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`asset-stock/${uuid}/checkout-list`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
